import React from 'react'
import { ShadowButton } from "@/components/ui/button";

export default function greenButton({children, onClick}) {
  return (
    <ShadowButton
        onClick={onClick}
        variant={"neutralGreen"}
        href="/login"
        className="text-lato"
        >
        {children}
    </ShadowButton>
  )
}


import React, { useState, useEffect } from "react";
import { Form, Input, Button, Alert, Divider } from "antd";
import {
  GoogleSquareFilled,
  FacebookFilled,
  GithubFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./authProvider";
import GreenShadowButton from "components/buttons/greenButton";
export default function Singup() {
  const { loginOAuth, register, isAuthenticated } = useAuth();
  const [authError, setAuthError] = useState(false);
  const [passError, setPassError] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const navigateToLogin = () => {
    navigate("/login");
  };

  const signUp = async () => {
    const { password, confPassword } = form.getFieldsValue();
    if (password !== confPassword) {
      setPassError(true);
      return;
    }
    const check = await register(form.getFieldsValue());
    if (check) navigate("/dashboard");
    else setAuthError(true);
  };

  useEffect(() => {
    if (isAuthenticated) navigate("/dashboard");
  }, [isAuthenticated]);

  return (
    <>
      <div
        className="flex flex-col justify-center items-center login"
        style={{ height: "100vh" }}
      >
        {authError && (
          <Alert
            message="Invalid Username or Password!"
            description="Please enter valid credentials!"
            type="error"
            showIcon
          />
        )}
        {setPassError && (
          <Alert
            className="mb-2"
            message="Passwords Do not Match!"
            description="Please enter same password!"
            type="error"
            showIcon
          />
        )}
        <div className="flex flex-col justify-center items-center md:w-3/6 w-full">
          <span className="text-lato md:text-3xl text-xl">
            Ready to{" "}
            <span className="text-lato" style={{ color: "#7BF27C" }}>
              Shape Your Future?
            </span>
          </span>
          <span
            className="text-lato flex mt-2 md:w-3/6 w-full md:text-lg text-xs"
            style={{ color: "grey", textAlign: "center" }}
          >
            Create Your Account and Ignite Your Passion for Programming
          </span>
          <div className="flex flex-col w-full md:w-3/6 mt-8">
            <Form form={form} layout="vertical">
              <Form.Item className="text-lato" name={"email"} required={true}>
                <Input
                  type="email"
                  style={{ borderColor: "black" }}
                  placeholder="Enter your Email"
                />
              </Form.Item>
              <Form.Item
                className="text-lato"
                name={"password"}
                required={true}
              >
                <Input.Password
                  style={{ borderColor: "black" }}
                  placeholder="Choose your Password"
                />
              </Form.Item>
              <Form.Item
                className="text-lato"
                name={"confPassword"}
                required={true}
              >
                <Input.Password
                  style={{ borderColor: "black" }}
                  placeholder="Enter Password Again"
                />
              </Form.Item>

              <Form.Item>
                <div className="flex justify-center">
                  <GreenShadowButton
                    onClick={signUp}
                    className="text-lato"
                  >
                    Sign Up
                  </GreenShadowButton>
                </div>
              </Form.Item>
            </Form>
            <span className="w-full text-center" style={{ color: "grey" }}>
              Already have an account ?
              <span
                onClick={navigateToLogin}
                style={{ color: "black", cursor: "pointer" }}
              >
                Login
              </span>
            </span>
            <Divider plain>
              <span className="text-lato">or</span>
            </Divider>
            <div className="flex flex-row justify-center gap-10">
              <button type="button" onClick={() => loginOAuth("google")}>
                <GoogleSquareFilled style={{ fontSize: "40px" }} />
              </button>
              {/* <button type="button" onClick={() => loginOAuth("facebook")}>
                <FacebookFilled style={{ fontSize: "40px" }} />
              </button> */}
              <button type="button" onClick={() => loginOAuth("github")}>
                <GithubFilled style={{ fontSize: "40px" }} />
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

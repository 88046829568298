import React, { useState } from "react";
import DashboardHeader from "../../components/header/dashboardHeader";
import { Form, Button, Input, message, Result, Select } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import HomeHeader from "../../components/header/homeHeader";
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import supabase from "../supa-base-client/supabase";

export default function Contact() {
  const [searchParams] = useSearchParams();
  const [success, setSuccess] = useState();
  const form = Form.useForm();
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  const types = [
    { id: 5, name: "Incident" },
    { id: 1, name: "Service" },
    { id: 2, name: "Feature" },
    { id: 3, name: "Bug" },
    { id: 4, name: "Maintenance" },
    { id: 6, name: "Access" },
    { id: 8, name: "Feedback" },
  ];

  const showError = () => {
    messageApi.open({
      type: "error",
      content: "Something went wrong!\nPlease Try again!",
    });
  };

  const submitRequest = async () => {
    let req = form.current.getFieldsValue();
    let data = {
      priority:"Low",
      createdBy:{
        name:"Landing Page"
      },
      ...req
    };
    try{
      const resp = await fetch(`https://helpdesk.knowvationlearnings.in/api/v1/ticket/public/create`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const res = await resp.json();
      console.log({ res });
      if(res.success !== true)
      {
        showError();
        return;
      }
      setSuccess(true);
    }
    catch(e){
      console.error(e);
      showError();
    }
    
  };

  return (
    <>
      <div className="flex flex-col w-full justify-center items-center mt-5">
        <div
          className="flex course-box flex-col justify-center items-center gap-3"
          style={{ width: "70%" }}
        >
          {contextHolder}
          {!success && (
            <>
              <span className="text-poppins md:text-3xl text-sm">
                Fill your details
              </span>
              <Form ref={form} layout="horizontal" className="w-2/3">
                <Form.Item className="text-lato" label="Name" name="name">
                  <Input placeholder="Enter Your Name" />
                </Form.Item>
                <Form.Item className="text-lato" label="Email" name="email">
                  <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item className="text-lato" label="Type" name="type">
                  <Select placeholder="Select Type" options={types.map(x => ({label:x.name, value:x.name}))} />
                </Form.Item>
                <Form.Item className="text-lato" label="Subject" name="title">
                  <Input placeholder="Enter Subject" />
                </Form.Item>
                <Form.Item className="text-lato" label="Message" name="detail">
                  <Input.TextArea
                    placeholder="Enter your Message for our Team"
                    rows={5}
                  />
                </Form.Item>
                <Form.Item>
                  <div className="flex justify-center">
                    <Button
                      onClick={submitRequest}
                      className="text-lato"
                      style={{
                        width: "80%",
                        padding: 10,
                        borderColor: "black",
                        boxShadow: "10px 10px 0px 4px #7BF27C",
                      }}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                      {/* Apply Now */} Send Request
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            </>
          )}
          {success && (
            <>
              <Result
                status="success"
                title="Successfully Request Sent!"
                subTitle="Thank you for reaching out to us. Our team will get back to you as soon as possible."
                extra={[
                  <Button
                    // onClick={navigateToPageInfo}
                    href="/#explore"
                    className="text-lato md:w-3/12 w-3/4"
                    style={{
                      padding: 10,
                      borderColor: "black",
                      boxShadow: "4px 6px 0px 4px #7BF27C",
                    }}
                  >
                    Go to Home
                  </Button>,
                ]}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}

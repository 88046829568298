import { useContext, createContext, useState, useEffect } from "react";
import supabase from "../supa-base-client/supabase";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);
  const [isAuthenticated, setAuthenticated] = useState(
    localStorage.getItem("kl-auth" || false)
  );

  const onboardingURL = "http://localhost:3000";
  //const onboardingURL = "https://knowvationlearnings.in/dashboard";

  useEffect(() => {
    async function init() {
      await isLoggedIn();
      await getUser();
    }
    init();
  }, []);

  useEffect(() => {
    async function fetchUser() {
      await getUser(session?.user.id);
    }
    fetchUser();
  }, [session]);
  // method get user with random id for testing - name,id,role

  const getUser = async () => {
    try {
      const {
        data: { user },
        error: authUserError,
      } = await supabase.auth.getUser();

      const { data: userDetails, error: userError } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", user.id);
      let user_metadata = {};
      user_metadata.id = user.id;
      user_metadata.email = user.email;
      if (userDetails && userDetails.length > 0) {
        user_metadata.name = userDetails[0].name;
        user_metadata.avatar = userDetails[0].name.charAt(0);
      } else if (user.user_metadata) {
        user_metadata.name = user.user_metadata.full_name;
        user_metadata.avatar = user.user_metadata.avatar_url;
      }

      setUser(user_metadata);
    } catch (err) {
      console.log(err);
    }
  };

  const login = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error !== null || data.session === null) {
        return false;
      }
      if (data) {
        setSession(data.session);
      }
      localStorage.setItem("kl-auth", true);
      setAuthenticated(true);
      return true;
    } catch (err) {
      console.log(err);
    }
  };

  const loginOAuth = async (provider) => {
    try {
      if (provider) {
        const { error, data } = await supabase.auth.signInWithOAuth({
          provider,
          options: {
            redirectTo: onboardingURL,
          },
        });
        if (error) {
          console.error(error);
          return false;
        }
        localStorage.setItem("kl-auth", true);
        setAuthenticated(true);
        return true;
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const register = async (details) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email: details.email,
        password: details.password,
        options: {
          emailRedirectTo: onboardingURL,
        },
      });
      if (error) {
        console.error(error);
        return false;
      }
      return true;
    } catch (err) {
      console.error(err);
    }
  };

  const logout = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error !== null) {
        console.error(error);
        return false;
      }
      localStorage.removeItem("kl-auth");
      setAuthenticated(false);
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  const isLoggedIn = async () => {
    try {
      const { data, error } = await supabase.auth.getSession();

      if (error !== null || data.session === null) {
        return false;
      }

      setSession(session);
      setAuthenticated(true);
      localStorage.setItem("kl-auth", true);
      return true;
    } catch (err) {
      console.error(err);
    }
    return false;
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        getUser,
        login,
        loginOAuth,
        register,
        logout,
        isLoggedIn,
        isAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthProvider;
export const useAuth = () => {
  return useContext(AuthContext);
};

import React, { useEffect, useState } from "react";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";
import PaymentFail from "./paymentFail";
import { useSearchParams } from "react-router-dom";
import { counter } from "@fortawesome/fontawesome-svg-core";
import { useCounter } from "pages/context/counterContext";

const PaymentStatus = () => {
  const navigate = useNavigate();
  const { removeItem, isExists } = useCounter();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState(searchParams.get("order_id") || null);
  const [status, setStatus] = useState("");

  const goToCourses = () => {
    navigate("/courses");
  };
  const goToDashBoard = () => {
    navigate("/dashboard");
  };

  let message = "";

  useEffect(() => {
    async function verifyPaymentStatus() {
      try {
        console.log("order details function called");
        if (id) {
          const res = await fetch(
            `https://api.knowvationlearnings.in/api/v1/pay/getstatus/${id}`
          );
          const info = await res.json();
          console.log(info, "info");
          switch (info.order_status) {
            case "PAID":
              setStatus("success");
          }
        }
      } catch (err) {
        console.log(err, "error");
      }
    }
    verifyPaymentStatus();
    if (status === "success") {
      const checkoutId = localStorage.getItem("checkout_course");
      if (checkoutId && isExists(checkoutId)) {
        removeItem(checkoutId, "cart");
        localStorage.removeItem("checkout_course");
      }
    }
  }, [id]);

  return (
    <>
      {status === "success" ? (
        <Result
          status="success"
          title="Payment Successful!"
          subTitle="Your Educational Transformation starts now!"
          extra={[
            <p>Your Transaction ID : {id}</p>,
            // <Button onClick={goToDashBoard} type="primary" key="console">
            //   Go To Dashboard
            // </Button>,
            <Button onClick={goToCourses} key="buy">
              Browse Courses
            </Button>,
          ]}
        />
      ) : (
        <Result
          status="error"
          title="Payment is not succesfull!"
          subTitle="If money has been deducted from your account. Please contact our support team."
          extra={[
            <p>Your Transaction ID : {id}</p>,
            // <Button onClick={goToDashBoard} type="primary" key="console">
            //   Go To Dashboard
            // </Button>,
            <Button onClick={goToCourses} key="buy">
              Browse Courses
            </Button>,
          ]}
        />
      )}
    </>
  );
};
export default PaymentStatus;

import React from 'react'
import { ShadowButton } from "@/components/ui/button";

export default function pinkButton({children, onClick}) {
  return (
    <ShadowButton
        onClick={onClick}
        variant={"neutralPink"}
        href="/login"
        className="text-lato"
        >
        {children}
    </ShadowButton>
  )
}


import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import supabase from "pages/supa-base-client/supabase";
import { v4 as uuidv4 } from "uuid";
import {
  CheckOutlined,
  CloseOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Switch,
  Card,
  Flex,
  TimePicker,
  Upload,
  Result,
  Descriptions,
  notification,
  Divider
} from "antd";
import {
  getCouponValidity,
  getCourseBatch,
  getNotification,
  getPaymentResponse,
} from "util/methods";
const Option = { Select };

const Forms = ({ id, requirePayment = false }) => {
  if (!id || id === null || id == "") return <></>;

  const [form] = Form.useForm();
  const [fields, setFields] = useState([]);
  const [formData, setFormData] = useState({});
  const [type, setType] = useState("Submit");
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [api, contextHolder] = notification.useNotification();

  const applyCoupon = async () => {
      const values = form.getFieldsValue();
      const { coupon } = values;
      const res = await getCouponValidity(coupon);
      console.log(res)
      if (!res || !res?.valid) {
        getNotification(
          api,
          "error",
          "Coupon invalid",
          "please enter the valid coupoun code furthur queries please contact the coupon provider"
        );
        return;
      }
      getNotification(
        api,
        "success",
        "coupon valid",
        "please proceed to checkout to activate the coupon discount"
      );
    };

  useEffect(() => {
    const formProm = supabase.from("forms").select().eq("id", id);
    formProm
      .then(({ data, error }) => {
        console.log({ data, error });
        if (error !== undefined && error !== null) {
          //window.location.href = "/";
        }
        const formRes = data[0];
        switch(formRes.related_to){
          case 'event':
            setType("Register");
            break;
          case 'career':
              setType("Apply");
              break;
          default:
              setType("Submit");
              break;
        }
        const fieldsProm = supabase
          .from("form_fields")
          .select()
          .eq("form_id", formRes.id)
          .order("order", { ascending: true });
        fieldsProm.then((data) => {
          setFields(data.data);
        });
        setFormData(formRes);
      })
      .catch(() => {
        window.location.href = "/";
      });
  }, [id]);

  const onFinish = (values) => {
    Object.keys(values).forEach((x) => {
      if (x.includes("FileInput")) {
        let file = values[x].file;

        const file_id = uuidv4() + file.originFileObj.name;
        values[x] = file_id;
        supabase.storage
          .from("form-files")
          .upload(file_id, file.originFileObj, {
            cacheControl: "3600",
            upsert: false,
          });
      }
    });

    const prom = supabase
      .from("responses")
      .insert({ form_id: formData.id, data: values });
    prom.then(({ error }) => {
      if (error == null) setSuccess(true);
      else setError(true);
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      {contextHolder}
      {isError && <Result status="error" title="Submission Failed"></Result>}
      {isSuccess && (
        <Result status="success" title="Submission Success"></Result>
      )}
      {!isError && !isSuccess && (
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          size="large"
        >
          {fields?.map((x) => {
            let input = <></>;
            let name = x.name;
            switch (x.type) {
              case "text": {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
              case "textarea": {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
              case "number": {
                input = (
                  <InputNumber
                    size="large"
                    placeholder={x.placeholder}
                    style={{ width: "100%" }}
                  />
                );
                break;
              }
              case "switch": {
                input = (
                  <Switch
                    size="large"
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                );
                break;
              }
              case "date": {
                input = <DatePicker size="large" style={{ width: "100%" }} />;
                break;
              }
              case "time": {
                input = <TimePicker size="large" style={{ width: "100%" }} />;
                break;
              }
              case "file": {
                name = name + "FileInput";
                input = (
                  <>
                    <Upload>
                      <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                  </>
                );
                break;
              }
              case "mcq": {
                input = (
                  <Select size="large" placeholder={x.placeholder}>
                    {x.data.map((y) => (
                      <Option key={y} value={y}>
                        {y}
                      </Option>
                    ))}
                  </Select>
                );
                break;
              }
              default: {
                input = <Input size="large" placeholder={x.placeholder} />;
                break;
              }
            }
            return (
              <Form.Item
                // layout={x.type === "textarea" ? "vertical" : "horizontal"}
                layout="horizontal"
                key={x.name}
                label={x.label}
                name={name}
                rules={[
                  {
                    required: x.is_required,
                    message: x.placeholder,
                  },
                ]}
              >
                {input}
              </Form.Item>
            );
          })}
          {requirePayment && <>
            <Form.Item className="text-lato" name="coupon">
              <div className="flex flex-row gap-4">
                <Input
                  style={{ height: "50px" }}
                  placeholder="Enter Coupon Code"
                />
                <Button
                  onClick={() => applyCoupon()}
                  style={{
                    boxShadow: "6px 6px 0px 3px #00B902",
                  }}
                  className="text-lato"
                >
                  Apply
                </Button>
              </div>
            </Form.Item>
            <Card title="Summary">
<Descriptions title="Event Info" layout="horizontal">
  <Descriptions.Item label="Selected Event" span={2}>
    {/* {selectedEvent()} */}
    Techknowthon 2.0
  </Descriptions.Item>
</Descriptions>

<Divider orientation="right" dashed>
  Sub Total : {"₹ "+  399 } x { 4 }
</Divider>
<Divider orientation="right">
  Grand Total : {"₹ "+ 1596}
</Divider>
</Card>
          </>}
          <Form.Item
            className="mt-4"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              {type}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
export default Forms;
